import * as React from 'react'
import * as Popover from '@radix-ui/react-popover'
import cn from 'classnames'
import { GenerateApiPopoverLoadingState } from './loading-state'

export type GenerateApiPopoverContentProps = {
  loading?: boolean
  generatingMessage?: string
  onCancel?: () => void
  content?: React.ReactNode
  contentProps?: Popover.PopperContentProps
}

export const GenerateApiPopoverContent = (
  props: GenerateApiPopoverContentProps
) => {
  const { loading, generatingMessage, onCancel, content, contentProps } = props

  return (
    <Popover.Portal>
      <Popover.Content
        align={contentProps?.align || 'end'}
        {...contentProps}
        className={cn(
          contentProps?.className,
          'w-[320px] bg-white p-4 mt-2 mb-2 rounded-md border-solid border border-gray-200 shadow-sm z-popover'
        )}
      >
        {loading && (
          <GenerateApiPopoverLoadingState
            generatingMessage={generatingMessage}
            onCancel={onCancel}
          />
        )}
        {!loading && content}
      </Popover.Content>
    </Popover.Portal>
  )
}
