import { Button, Loader } from 'src/design-system'
import * as React from 'react'
import * as Popover from '@radix-ui/react-popover'

export type GenerateApiPopoverLoadingStateProps = {
  generatingMessage?: string
  onCancel?: () => void
}

export const GenerateApiPopoverLoadingState = (
  props: GenerateApiPopoverLoadingStateProps
) => {
  const { generatingMessage, onCancel } = props

  return (
    <div className="flex flex-col gap-y-5">
      <div className="py-6 flex flex-col items-center justify-center gap-y-3 text-gray-600 text-center">
        <div className="flex flex-row items-center justify-center gap-x-3">
          <Loader variant="highlight" className="w-4 h-4" />
          <span>
            Generating<span aria-hidden>...</span>
          </span>
        </div>
        {generatingMessage}
      </div>
      <div>
        <Popover.Close asChild>
          <Button
            variant="outline"
            type="button"
            className="w-full max-w-none"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Popover.Close>
      </div>
    </div>
  )
}
