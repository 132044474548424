import { Button } from 'src/design-system'
import { Sparkle } from '@phosphor-icons/react'
import * as Popover from '@radix-ui/react-popover'
import * as React from 'react'
import { LoadingContent } from 'components/loading-content'

export type GenerateApiPopoverTriggerProps = {
  children: React.ReactNode
  loading: boolean
  onClick?: () => void
  naked?: boolean
}

export const GenerateApiPopoverTrigger = (
  props: GenerateApiPopoverTriggerProps
) => {
  const { children, loading, onClick, naked = false } = props

  return (
    <Popover.Trigger asChild>
      {naked ? (
        <span className="inline" onClick={onClick}>
          {children}
        </span>
      ) : (
        <Button
          colourVariant="highlight"
          onClick={onClick}
          type="button"
          variant="outline"
        >
          <LoadingContent
            className="h-4 w-4"
            loading={loading}
            variant="highlight"
          >
            <Sparkle
              aria-hidden
              className="h-4 text-pink-600 w-4"
              weight="fill"
            />
          </LoadingContent>
          {children}
        </Button>
      )}
    </Popover.Trigger>
  )
}
