import { store } from 'store/index'
import { trackEvent } from '../../services/event-tracker'

export class AiGenerateButtonVm {
  constructor(
    private activeTaskId: string | null,
    private asyncTaskList: string[]
  ) {}

  trackAiEvent(
    action: string,
    source: string,
    options?: Record<string, unknown>
  ) {
    trackEvent('$track_inline_ai', {
      action: action,
      source: source,
      ...options,
    })
  }

  get hasFailed() {
    return this.asyncTaskList.length > 0 && this.activeTask?.isFailure
  }

  get hasSucceeded() {
    return this.asyncTaskList.length > 0 && this.activeTask?.isSuccess
  }

  get canGenerate() {
    return this.asyncTaskList.length === 0 || this.activeTask?.isFailure
  }

  get activeTask() {
    if (!this.activeTaskId) return null

    return store.asyncTasks.byId(this.activeTaskId)
  }

  get activeTaskIndex() {
    return this.asyncTaskList.indexOf(this.activeTaskId || '')
  }

  get previousTaskId() {
    return (
      this.activeTaskIndex - 1 >= 0 &&
      this.asyncTaskList.at(this.activeTaskIndex - 1)
    )
  }

  get nextTaskId() {
    return (
      this.activeTaskIndex + 1 < this.asyncTaskList.length &&
      this.asyncTaskList.at(this.activeTaskIndex + 1)
    )
  }
}
