import * as React from 'react'
import * as Popover from '@radix-ui/react-popover'
import { GenerateApiPopoverTrigger } from './trigger'
import { GenerateApiPopoverContent } from './content'

export type GenerateApiPopoverButtonProps = {
  loading: boolean
  generatingMessage?: string
  onCancel?: () => void
  onClickTrigger?: () => void
  popoverContentProps?: Popover.PopperContentProps
  popoverContent:
    | React.ReactNode
    | (({ onClose }: { onClose: () => void }) => React.ReactNode)
  /**
   * Content for the trigger button
   */
  children?: string
  naked?: boolean
}

export const GenerateApiPopoverButton = (
  props: GenerateApiPopoverButtonProps
) => {
  const {
    loading,
    generatingMessage,
    onCancel,
    onClickTrigger,
    popoverContent,
    popoverContentProps,
    children,
    naked = false,
  } = props

  const [open, setOpen] = React.useState(false)

  const content =
    popoverContent instanceof Function
      ? popoverContent({ onClose: () => setOpen(false) })
      : popoverContent

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <GenerateApiPopoverTrigger
        onClick={onClickTrigger}
        loading={loading}
        naked={naked}
      >
        {children}
      </GenerateApiPopoverTrigger>
      <GenerateApiPopoverContent
        loading={loading}
        generatingMessage={generatingMessage}
        onCancel={onCancel}
        content={content}
        contentProps={popoverContentProps}
      />
    </Popover.Root>
  )
}
