import {
  GenerateApiPopoverButton,
  GenerateApiPopoverButtonProps,
} from 'components/generate-api-popover/generate-api-popover-button'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Button, Tooltip } from 'src/design-system'
import { store } from 'store/index'
import { errorToast } from '../../utils/error-toast'
import { successToast } from '../../utils/success-toast'
import { ChevronLeft, ChevronRight } from '@easy-eva-icons/react'
import { AsyncTaskOutput } from 'store/modules/async-tasks'
import { AiVmMap, AiVmMapKey } from './content/map'
import { AiGenerateButtonVm } from './ai-generate-button-vm'
import cn from 'classnames'

type AiGenerateButtonProps = {
  source: string
  onUse: (output: AsyncTaskOutput) => void | Promise<void>
  clearTaskListOnUse?: boolean
  contentType: AiVmMapKey
  inputs: Record<string, unknown>
  className?: string
  children?: string
  popoverContentProps?: GenerateApiPopoverButtonProps['popoverContentProps']
  tooltipContent?: string
  enablePreview?: boolean
  naked?: boolean
}

export const AiGenerateButton = observer((props: AiGenerateButtonProps) => {
  const {
    source,
    onUse,
    contentType,
    inputs,
    className,
    children,
    clearTaskListOnUse = true,
    popoverContentProps,
    tooltipContent,
    enablePreview = true,
    naked = false,
  } = props

  const aiVm = React.useMemo(() => {
    return new AiVmMap[contentType](source)
  }, [contentType, source])

  const [popoverContentLoading, setPopoverContentLoading] =
    React.useState(false)

  const [activeTaskId, setActiveTaskId] = React.useState<string | null>(null)
  const [asyncTaskList, setAsyncTaskList] = React.useState<string[]>([])

  const vm = React.useMemo(() => {
    return new AiGenerateButtonVm(activeTaskId, asyncTaskList)
  }, [activeTaskId, asyncTaskList])

  const startGeneration = async (
    additionalInputs: Record<string, unknown> | null
  ) => {
    setPopoverContentLoading(true)

    const taskId = await aiVm.onSubmit({
      ...inputs,
      ...additionalInputs,
    })

    if (!taskId) {
      setPopoverContentLoading(false)
      return
    }

    if (!enablePreview && taskId) {
      setActiveTaskId(taskId)

      await store.asyncTasks.byId(taskId)?.pollOne()
      const task = store.asyncTasks.byId(taskId)
      if (task?.isSuccess) {
        await onUse(task.output)
        setPopoverContentLoading(false)
        if (clearTaskListOnUse) setAsyncTaskList([])
        return
      }
    }

    setAsyncTaskList((prev) => [...prev, taskId])

    await store.asyncTasks.byId(taskId)?.pollOne()
    const asyncTask = store.asyncTasks.byId(taskId)

    setActiveTaskId(taskId)

    if (asyncTask?.isSuccess) {
      successToast(aiVm.onSuccessMessage)
    } else {
      errorToast(aiVm.onErrorMessage)
    }

    setPopoverContentLoading(false)
  }

  const onUseClick = () => {
    if (vm.activeTask?.isSuccess) {
      vm.trackAiEvent('accepted', source, {
        attempts: asyncTaskList.length,
        attemptSelected: vm.activeTaskIndex + 1,
      })

      onUse(vm.activeTask?.output)

      if (clearTaskListOnUse) setAsyncTaskList([])
    }
  }

  return (
    <div className={cn(className)}>
      <Tooltip content={tooltipContent} disabled={!tooltipContent}>
        <GenerateApiPopoverButton
          naked={naked}
          loading={popoverContentLoading}
          generatingMessage={aiVm.generatingMessage}
          onCancel={() => {
            vm.trackAiEvent('cancelled', source, {
              attempts: asyncTaskList.length,
            })
            setPopoverContentLoading(false)
            setAsyncTaskList([])
          }}
          onClickTrigger={() => {
            if (!aiVm.formContent) {
              startGeneration({})
            }
          }}
          popoverContentProps={popoverContentProps}
          popoverContent={({ onClose }) => (
            <div className="z-tooltip relative">
              {vm.hasFailed && (
                <div className="flex justify-between items-center text-center space-x-2 pb-4 text-gray-700">
                  {aiVm.onErrorMessage}
                </div>
              )}
              {vm.hasSucceeded && (
                <>
                  {vm.activeTask?.output && aiVm.content(vm.activeTask?.output)}
                  <div className="flex justify-between mt-6">
                    <div className="flex justify-between items-center space-x-2">
                      <Button
                        variant="outline"
                        disabled={!vm.previousTaskId}
                        onClick={() => {
                          vm.trackAiEvent('previous', source, {
                            attempts: asyncTaskList.length,
                          })
                          vm.previousTaskId &&
                            setActiveTaskId(vm.previousTaskId)
                        }}
                      >
                        <span className="sr-only">View previous content</span>
                        <ChevronLeft aria-hidden />
                      </Button>
                      <span className="text-xs text-gray-500">
                        {vm.activeTaskIndex + 1} of {asyncTaskList.length}
                      </span>
                      <Button
                        variant="outline"
                        disabled={!vm.nextTaskId}
                        onClick={() => {
                          vm.trackAiEvent('next', source, {
                            attempts: asyncTaskList.length,
                          })
                          vm.nextTaskId && setActiveTaskId(vm.nextTaskId)
                        }}
                      >
                        <span className="sr-only">View next content</span>
                        <ChevronRight aria-hidden />
                      </Button>
                    </div>

                    <div className="flex space-x-2">
                      <Button
                        colourVariant="paper"
                        onClick={() => {
                          vm.trackAiEvent('try-again', source, {
                            attempts: asyncTaskList.length,
                          })
                          startGeneration({})
                        }}
                      >
                        Try again
                      </Button>
                      <Button
                        className="flex items-center"
                        onClick={() => {
                          onUseClick()
                          onClose()
                        }}
                      >
                        Use
                      </Button>
                    </div>
                  </div>
                </>
              )}
              {vm.canGenerate && !popoverContentLoading && (
                <>
                  {!aiVm.formContent && (
                    <Button
                      className="w-full max-w-none"
                      type="button"
                      onClick={() => startGeneration({})}
                    >
                      {aiVm.generateButtonText}
                    </Button>
                  )}
                  {aiVm.formContent && (
                    <aiVm.formContent
                      inputs={inputs}
                      startGeneration={startGeneration}
                    />
                  )}
                </>
              )}
            </div>
          )}
        >
          {children}
        </GenerateApiPopoverButton>
      </Tooltip>
    </div>
  )
})
